import styled from 'styled-components'
import { IWidgetMeeting, IWidgetTable, TablePos } from '../../interfaces/IWidgetInfo'
import { useContext } from 'preact/hooks'
import { AppContext } from '../../app'
import { ListItemNew } from '../Table/ListItem/ListItem'
import { getItemRole } from '../../helpers/Tools'

interface TableProps {
    table: IWidgetTable | undefined | null
    meeting: IWidgetMeeting | null | undefined
    returnMentor: () => void
}

const ListTable = ({ table, returnMentor }: TableProps) => {
    const { widgetInfo } = useContext(AppContext)

    const isMe = (user: any) => {
        return widgetInfo?.me.profile._id === user?._profileId
    }

    const showPulseBtn = (item: TablePos) => {
        if (isMe(item)) return false
        if (!widgetInfo?.algorithm?.isMentor && item.isMentor) return false
        if (item.hrLevel) return false
        return true
    }

    const getUserLabel = (item: TablePos) => {
        if (table?.facilitator === item._profileId) {
            return 'roles.facilitator'
        }
        if (item.isMentor && !item.isExpertMentor) {
            return 'roles.mentor'
        }
        if (item.isExpertMentor) {
            return 'roles.expertMentor'
        }
        return ''
    }

    const showReturnButton = (item: TablePos) => {
        if (isMe(item)) {
            if (item.isMentor && !widgetInfo?.table?.isMentorTeam) {
                return true
            }
            if (item.isExpertMentor && !widgetInfo?.table?.isExpertMentorTeam) {
                return true
            }
        }
    }

    return (
        <TableContainer>
            <Table>
                <TableList>
                    {table?.positions.map(item => {
                        return (
                            <ListItemNew
                                canRedirect
                                item={item}
                                key={item._profileId}
                                role={getItemRole(item, table)}
                                leave={showReturnButton(item)}
                                pulse={showPulseBtn(item)}
                                isMentorTeam={table.isMentorTeam}
                                returnMentor={returnMentor}
                                userLabel={getUserLabel(item)}
                            />
                        )
                    })}
                </TableList>
            </Table>
        </TableContainer>
    )
}

export default ListTable

export const TableContainer = styled.div``

export const Table = styled.div`
    padding-bottom: 15px;
    width: 100%;
`

export const TableList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
`
